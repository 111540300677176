import React from 'react';
import './TrinetResult.css';

class TrinetResult extends React.Component {

    constructor(props) {
        super(props);
/*        this.ref = React.createRef()
        let intersecter = this.onIntersect.bind(this)
        this.observer = new IntersectionObserver(function (entries, observer) {
            entries.forEach((entry) => {
                if ( entry.isIntersecting ) {
                    intersecter()
            }})
        }); */
    }
/*    componentDidMount() {
        this.observer.observe( this.ref.current );
    }

    componentWillUnmount() {
        this.observer.disconnect()
    }
*/
    getUrl() {
        return this.props.url 
    }

    getMeta() {
        return this.props.meta
    }

    render() {
        const {hasSubresults,domain,collapse,unfolded} = this.props
        return <div ref={this.ref} className="result-item">
            {this.getUrl() ? this.props.api.getRenderer( this.props.project )( this.props.api, this.props.result, this.props.lang, this.getUrl(), this.getMeta(), this.props.project, this.props.hasSubresults ) : ''}
            {hasSubresults ? <a className="result-item-others" onClick={e=>collapse(domain)}>{unfolded?'▾':'▸'}&nbsp; {hasSubresults} autres résultats dans {domain}</a> : ''}
        </div>
    }
} 

export default TrinetResult;

