import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import TrinetApi from './trinetApi'
import renderMastodon from './renderers/renderMastodon'
import renderMastodonLinks from './renderers/renderMastodonLinks'


if ( process.env.REACT_APP_WIDGET == 'true' ) {

  const widgetDivs = document.querySelectorAll('.trinet-widget');
  widgetDivs.forEach(div => {

    const project = div.getAttribute('data-trinet-project')
    const lang = div.getAttribute('data-trinet-lang')
    const query = div.getAttribute('data-trinet-query')

    const api = new TrinetApi(
      process.env.REACT_APP_BACKEND,
      '?'+project+'/'+lang+'/'+query,
      {nohash:true,noscroll:true}
    )
    api.registerRenderer('mastodon',renderMastodon)
    api.registerRenderer('mastodonlinks',renderMastodonLinks)

      const e = ReactDOM.createRoot(div);
      e.render(
          <App api={api} type="widget"/>
      )
  })

} else {

  const api = new TrinetApi(
    process.env.REACT_APP_BACKEND,
    process.env.REACT_APP_ENTRY
  )
  api.registerRenderer('mastodon',renderMastodon)
  api.registerRenderer('mastodonlinks',renderMastodonLinks)
    
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App api={api} type="page"/>
    </React.StrictMode>
  );

}
