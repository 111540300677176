import React from 'react';
import './LinkBox.css'

export default class LinkBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            image:false,
            title:false
        }
    }

    componentDidMount() { 
        this.props.api.post({
            action:'get_url_meta',
            url:this.props.link
        },(result)=>{
            this.setState({ title:result.url_meta.title,image:result.url_meta.image })
        })

    }

    showDomain() {
        return this.props.hasOwnProperty('showDomain') ? this.props.showDomain : true
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    

    render() {

        const domainMatch = this.props.link.match( /^https?:\/\/(?:www\.)?([^\/]+)\.[a-z]+\// )
        const domain = domainMatch? <div className="urldomain">{this.capitalizeFirstLetter(domainMatch[1].replace(/[\._0-9-]+/g,' '))}</div> : false

        return this.state.title ? <a className="content urlcontent" href={this.props.link} target="_blank">
                { this.state.image ? <div className="leftbox">
                    <img src={ this.props.api.cacheDisplayUrl(this.state.image) }/>
                </div> : <></> }
                <div className="rightbox urlbox">
                    {this.state.title}
                    {domain}
                </div>
            </a> : <></>
    }

}