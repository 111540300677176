import React from 'react';
import TrinetForm from './TrinetForm'
import TrinetResults from './TrinetResults'
import LoginForm from './LoginForm';

class App extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return <div className="search_form">
        <div className="form">
          <div className="inner-form">
          <TrinetForm api={this.props.api} limit={1000}/>
          <LoginForm api={this.props.api} />
          <TrinetResults 
            api={this.props.api}
          />
        </div>
      </div>
    </div>
  }
}

export default App;
