import $ from 'jquery';
import React from 'react';
import logo from './Mastodon.png'

function stripTags(textToEscape) {
    return textToEscape.split(/<\s*br\s*(?:\/\s*)?>|<\s*\/\s*p\s*>\s*<\s*p\s*>/).map(
        row => {
            let acc = []
            const patt = $('<p></p>').html(row).text().split(/(https?:\/\/)([^\/]+\/)(\S+)/g)
            for ( let i = 0 ; i < patt.length ; ) {
                acc.push(<span>{patt[i]}</span>)
                if ( ++i < patt.length ) {
                    acc.push(<a href={patt[i]+patt[i+1]+patt[i+2]}
                        target="_blank"
                        onClick={e=>{e.stopPropagation()}}>{patt[i]}{patt[i+1]}...</a>)
                }
                i += 3
            }
            return acc
        }
    )
}

export default function renderMastodonLinks( api, id, lang, url, meta ) {

    url = url.replace(/^https?:\/\/web\.archive\.org\/web\/[0-9]+\//,'')

    let pregs = url.match( /^https?:\/\/([^\/]+)/ )
    let hostname = pregs ? pregs[1] : ''

    let title = meta.title ? <a href={url} className="title" dangerouslySetInnerHTML={{__html:meta.title}}></a> : <a href={url}>{url}</a>
    let datec = ( meta.created_at && typeof meta.created_at == 'number' )? new Date(meta.created_at*1000) : 0
    let created_at = datec
    ? <a className="badge">{datec.toDateString()}</a>
    : ''

    let body = meta.body ? <div className="body">{stripTags(meta.body)}</div> : ''

    const [_,instance,author] = meta.mastodon ? ( meta.mastodon.match(/https?:\/\/([^\/]+)\/(@[^\/]+)\//) || [false,false,false] ) : [false,false,false]

    return <>
        <div className="header">
            <div className="author" onClick={api.addMeta.bind(api,{'domain':hostname})}>{hostname}</div>
        </div>
        <div className="content">
            <div className="rightbox">
               <p className="footer">{created_at}
               {instance ? <a href={meta.mastodon} target="_blank"><img className="logo" src={logo}/>{instance} {author}</a> : ''}
               </p>
               <p className="resume" >{title}</p>
               <p className="resume" dangerouslySetInnerHTML={{__html:body}}></p>
            </div>
        </div>
    </>
}