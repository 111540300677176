import React from 'react';
import ControlledInput from './controlledInput';
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import button_img from './img/search-2-line.png'

class TrinetForm extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        query:props.api.query,
        lang:props.api.lang,
        project:props.api.projectName,
        projects:[],
        sortBy:TrinetForm.optionsOrderBy[1],
        metaWords:[], // The list of metaword types that exist in current project e.g. ['instance','author']
        known:{}, // type -> list of values
        selected:[], // An array of [type,value] metawords 'type:value'
        resultCount:0,
        allCount:0
      }
      this.inQuery = false
      this.t = props.api.common.t
    }

    componentDidMount() { 
        this.props.api.registerAsView('projects',this)
        this.props.api.registerAsView('meta',this)
        this.props.api.registerAsView('projectName',this)
        this.props.api.registerAsView('metawordCounts',this)
        this.props.api.registerAsView('resultCount',this)
        this.props.api.registerAsView('query',this)
        this.updateProjects(this.props.api.projects)
    }

    componentWillUnmount() {
        this.props.api.unregisterAsView('projects',this)
        this.props.api.unregisterAsView('meta',this)
        this.props.api.unregisterAsView('projectName',this)
        this.props.api.unregisterAsView('metawordCounts',this)
        this.props.api.unregisterAsView('resultCount',this)
        this.props.api.unregisterAsView('query',this)
    }

    /** */
    splitMetaword( word, prefixes ) {
        for ( const prefix of prefixes ) {
            if ( word.startsWith(prefix+':') ) {
                return [prefix,word.substring(prefix.length+1)]
            }
        }
        return ['',word]
    }

    /**
     * @param {*} counts Object where key is a metaword as String (:-separated string) and value is its number of occurences
     */
    updateMetawordCounts(counts) {
        const prefixes = this.props.api.getMetaWords()
        let known = this.state.known
        for ( let word in counts ) {
            const count = counts[word]
            word = this.splitMetaword(word,prefixes)
            let current = known.hasOwnProperty(word[0]) ? known[word[0]] : []
            if ( ! current.some( f => f[0] == word[1] ) ) {
                current.push([word[1],count])
                known[word[0]] = current
            }
        }
        this.setKnown(known)
    }

    updateResultCount(count,lang) {
        this.setState({resultCount:count,resultLang:lang})
    }

    updateAllCount(count) {
        this.setState({allCount:count})
    }

    updateQuery( query, metawords ) {
        if ( !this.inQuery ) {
            this.setState({query:query, selected:metawords})
            this.onQuery({query:query, selected:metawords})
        }
    }

    setKnown(known) {
        for ( const metaword in known ) {
            known[metaword].sort(
                (a,b) => {
                    if (( !Array.isArray(a) )&& Array.isArray(b)) return -1;
                    if (Array.isArray(a) && !Array.isArray(b)) return +1;
                    if (( !Array.isArray(a) )&& !Array.isArray(b)) return a>b ? +1 : -1
                    return (a[1]>b[1])?-1:+1
                }
            )
        }
        this.setState({known:known})
    }

    updateProjects(projects) {
        if ( projects.length && !this.state.projects.length ) {
            const {query,metawords,projects} = this.props.api
            this.setState({
                projects:projects,
                metaWords:this.props.api.getMetaWords(),
                query:query,
                selected:metawords
            })
            if ( query || metawords ) this.onQuery({ query:query, selected:metawords, projects:projects })
        }
    }

    useTimestamps(options) {

        options = options || {}
        const projects = options.projects || this.state.projects
        const project = options.project || this.state.project

        return projects.some( p => ( p.name == project )&& p.useTimestamps  )
    }

    setLang(lang) {
        this.props.api.setLang(lang.value)
    }

    knownMeta(meta) {
        let known = this.state.known
        this.state.metaWords.forEach(
            metaword => {
                if ( meta.hasOwnProperty(metaword) ) {
                    let value = meta[metaword]
                    if ( known.hasOwnProperty(metaword) ) {
                        if ( ! known[metaword].some( f => f[0] == value ) ) {
                            known[metaword].push([value,0])
                        }
                    } else {
                        known[metaword] = [ [value,0] ]
                    }
                }
            }
        )
        this.setKnown(known)
    }

    /**
     * 
     * @param {*} meta an object with content {type:value} for adding metaword 'type:value'
     */
    addMeta(meta) {
        let selected = this.state.selected
        for ( const type in meta ) {
            const element = [ type, meta[type] ]
            if ( !selected.some( s => s[0] == type && s[1] == meta[type] ) ) selected.push( element )
        }
        this.setState({selected:selected})
        this.onQuery({selected:selected})
    }

    updateProjectName(projectName,lang) {
        this.setState({
            project:projectName,
            lang:lang,
            metaWords:this.props.api.getMetaWords()
        })
    }
      
    onQuery( options={} ) {

        this.inQuery = true
        const query = options.query || this.state.query
        const selected = options.selected || this.state.selected

        let payload = {
            action:'query',
            lang:options.lang || this.state.lang,
            project:this.state.project,
            sortBy:this.useTimestamps(options)?this.state.sortBy.value:'false',
            metaWords:JSON.stringify(selected),
            limit:this.props.limit
        }

        const match = query.match(/^like\s*:\s*(.*)$/)
        if ( match ) {
            payload.url = match[1]
        } else {
            payload.query = query || ''
        }

        this.props.api.notifyChanges( { query:query, metawords: selected } )

        this.props.api.post(payload)
        this.inQuery = false
    }

    renderProjectPicker() {
        return <div className="input-select"><Select placeholder="Project"
            classNames={TrinetForm.selectClassNames}
            styles={TrinetForm.selectStyles}
            options={this.state.projects.map(
                project => {
                    return { value:project.name, label:project.name }
                }
            )} 
            value={this.state.project ? {value:this.state.project,label:this.state.project} : false} 
            onChange={this.setProject.bind(this)}/>
        </div>
    }

    renderLangPicker() {
        let currentProjectName = this.state.project
        let currentProject = this.state.projects.find( project => project.name == currentProjectName )
        return currentProject ? <div className="input-select"><Select placeholder="Langue"
            classNames={TrinetForm.selectClassNamesNoBorder}
            styles={TrinetForm.selectStyles}
            options={currentProject.langs.map(
                lang => { return { value:lang, label:TrinetForm.langValueToLabel(lang) } }
            )}
            value={ this.state.lang ? {value:this.state.lang,label:TrinetForm.langValueToLabel(this.state.lang)} : false }
            onChange={this.setLang.bind(this)}
            />
        </div> : ''
    }

    capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    

    getKnown( metaword ) {
        return this.state.known.hasOwnProperty(metaword) ? this.state.known[metaword] : []
    }

    /**
     * 
     * @param {*} metaword A metaword type e.g. instance, author ...
     * @returns An option list for Select component, with all selected metawords for given type
     */
    getSelectedOptions( metaword ) {
        let options = []
        this.state.selected.forEach( selected => {
            const [type,value] = selected
            if ( type == metaword ) {
                options.push( {value:value,label:value} )
            }
        })
        return options
    }

    renderMetawordsPicker() {
        
        const optionColor = (isFocus,isSelected) => isSelected ? '#fff': ( isFocus ? '#6364ff' : '#777' ) 
        const CustomOption = (props) => {
            const { innerProps, innerRef } = props;
            return <div className="menu-list-option" ref={innerRef} {...innerProps} style={{color:optionColor(props.isFocused,props.isSelected)}}>
                {props.label} {props.data.count ? <span>{props.data.count}</span> : ''}
            </div>
        }
        const components = {Option: CustomOption}
        const noQuery = ! this.state.query

        return <React.Fragment>
            {this.state.metaWords.map( metaword => {
                let displayWord = this.capitalizeFirstLetter( metaword )
                return <div className="input-field" key={metaword}>
                    <div className="input-select">
                    <CreatableSelect placeholder={displayWord} isClearable isMulti 
                        onChange={this.setMetaWord.bind(this,metaword)} 
                        classNames={TrinetForm.selectClassNames}
                        components={components}
                        options={
                            this.getKnown(metaword).map(
                                value => {
                                    return Array.isArray(value) ? { value:value[0], label:value[0], count:noQuery?value[1]:false} :
                                        { value:value, label:value, count:false }
                                }
                            )
                        }
                        styles={TrinetForm.selectStyles}
                        value={ this.getSelectedOptions(metaword) }
                    />
                </div>
                </div>
            })}

        </React.Fragment>
    }

    setSortBy(what) {
        this.setState({sortBy:what})
    }

    renderSortControls() {
        return this.useTimestamps() ? <div className="input-select">
            <Select placeholder="Trier par ..." 
                options={TrinetForm.optionsOrderBy} 
                value={this.state.sortBy} 
                classNames={TrinetForm.selectClassNames}
                styles={TrinetForm.selectStyles}
                onChange={this.setSortBy.bind(this)}/>
        </div> : ''
    }

    renderExternalLinkCheckbox() {
        const isSet = ( this.state.project === 'mastodonlinks' )
        return (( this.state.project === 'mastodon' )||( this.state.project === 'mastodonlinks' ))
        ? <><label><input 
            type="checkbox" 
            checked={isSet} 
            onChange={ e => this.setProject( {value: e.target.checked ? 'mastodonlinks' : 'mastodon' } )}
        />External links</label></>
        :''
    }

    setProject(project) {
        this.props.api.setProjectName(project.value)
    }

    /**
     * @param {*} type A metaword type e.g. 'instance', 'author'
     * @param {*} value An array of {label,value} where value is the metaword value.
     */
    setMetaWord( type, value ) {
        // remove old metawords of type type
        let selected = this.state.selected.filter( metaword => metaword[0] != type )
        // insert new metawords of type type given in parameter value
        value.forEach( v=> {
            selected.push([type,v.value])
        })
        this.setState({selected:selected})
    }

    setLang( option ) {
        this.props.api.setLang( option.value )
    }

    onEnter(e) {
        this.onQuery()
        e.target.blur()
        this.props.api.scrollToResults()
    }

    render() {
        if ( ! this.state.projects ) {
            return <div className="trinet_form_loading"></div>
        }
        return <>
            {( this.state.project && this.state.lang )? <div className="basic-search">
                <div className="input-field">
                <ControlledInput
                    withSaveButton={button_img}
                    placeholder="Mots clefs"
                    type='text' property='query' container={this} onSubmit={this.onEnter.bind(this)}/>
                <div className="icon-wrap" onClick={this.onEnter.bind(this)}>
                    <svg version="1.1" width="20" height="20" viewBox="0 0 20 20">
                        <path d="M18.869 19.162l-5.943-6.484c1.339-1.401 2.075-3.233 2.075-5.178 0-2.003-0.78-3.887-2.197-5.303s-3.3-2.197-5.303-2.197-3.887 0.78-5.303 2.197-2.197 3.3-2.197 5.303 0.78 3.887 2.197 5.303 3.3 2.197 5.303 2.197c1.726 0 3.362-0.579 4.688-1.645l5.943 6.483c0.099 0.108 0.233 0.162 0.369 0.162 0.121 0 0.242-0.043 0.338-0.131 0.204-0.187 0.217-0.503 0.031-0.706zM1 7.5c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5-6.5-2.916-6.5-6.5z"></path>
                    </svg>
                </div>

                </div>
            </div> : '' }
            <div className="advance-search">
                <div className="row">
                    { this.props.api.hasProjectControl() ? <React.Fragment>
                        <div className="input-field">
                            {this.renderProjectPicker()}
                        </div>
                    </React.Fragment> : '' }
                    {this.renderMetawordsPicker()}
                    {this.useTimestamps() ? <div className="input-field">
                        {this.renderSortControls()}
                    </div> : ''}
                </div>
                <div className="row third">
                    <div className="input-field">
                        <div className="left-box">
                            {this.renderExternalLinkCheckbox()}
                            <div className="result-count">
                            { this.state.resultCount ? <>
                                <span>{this.state.resultCount}</span> {this.t('results in')} {this.state.allCount}</> : '' }
                                {this.renderLangPicker()}
                            </div>
                        </div>
                        <div className="group-btn">           
                            <button className="btn-search" onClick={this.onQuery.bind(this)}>{this.t('Filter')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
  }

TrinetForm.langOptions = [
    {value:'en',label:'English'},
    {value:'fr',label:'Français'},
    {value:'ar',label:'Arabe'},
    {value:'da',label:'Danois'},
    {value:'nl',label:'Néerlandais'},
    {value:'fi',label:'Finlandais'},
    {value:'de',label:'Allemand'},
    {value:'hu',label:'Hongois'},
    {value:'it',label:'Italien'},
    {value:'no',label:'Norvégien'},
    {value:'pt',label:'Portugais'},
    {value:'ro',label:'Roumain'},
    {value:'ru',label:'Russe'},
    {value:'es',label:'Espagnol'},
    {value:'sv',label:'Suédois'}
]

TrinetForm.langValueToLabel = value => TrinetForm.langOptions.find( option => option.value == value )?.label


TrinetForm.optionsOrderBy = [
    {value:'false',label:'Relevance'},
    {value:'stamp:-1',label:'Most recent first'}
]

TrinetForm.selectClassNames = {
    'control': (_state) => 'choices__inner',
    'container': (_state) => 'choices',
    'menu': (_state) => 'choices__list',
    'menu-list': (_state) => 'menu-list',
    'option': (_state) => 'menu-list-option',
    'indicatorSeparator': (_state)=>'indicatorSeparator',
    'multiValue':(_state)=>'multiValue',
    'singleValue':(_state)=>'singleValue'

}

TrinetForm.selectClassNamesNoBorder = { 
    ...TrinetForm.selectClassNames,
    'control': (_state) => 'choices__inner__noborder'
}

TrinetForm.selectStyles = { 
    option : (base,state) => {
        let color = '#777'
        if ( state.isFocused ) color = '#6364ff'
        if ( state.isSelected ) color = '#fff'
        return { ...base, color: color, backgroundColor:'#000' }
    }
}


export default TrinetForm;
  
