import React from 'react';
import './TrinetResults.css';
import TrinetResult from './TrinetResult'

class TrinetResults extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            results:[],
            project:false,
            lang:false,
            groupDomains:true,
            domains:{},
            metas:[],
            unfoldedDomains:[],
            loading:false
        }
        this.loading = false
        this.resultsRef = React.createRef()  
        this.bottomRef = React.createRef()
        const intersecter = this.onIntersect.bind(this)
        this.observer = new IntersectionObserver(function (entries, observer) {
            entries.forEach((entry) => {
                if ( entry.isIntersecting ) {
                    intersecter()
            }})
        });
        this.alreadyObeserving = false
    }

    componentDidMount() { 
        this.props.api.registerAsView('results',this)
        this.props.api.registerAsResultBox( this.resultsRef )
    }

    componentDidUpdate() {
        if ( ( ! this.alreadyObeserving ) && this.bottomRef.current ) {
            this.observer.observe( this.bottomRef.current );
            this.alreadyObeserving = true
        }
    }

    componentWillUnmount() {
        this.observer.disconnect()
        this.props.api.unregisterAsView('results',this)
        this.props.api.unregisterAsResultBox( this.resultsRef )
    }

    onIntersect() {
        if ( !this.loading ) this.loadNextMeta()
    }

    loadNextMeta( c = 10 ) {
        this.loading = true
        this.setState({loading:true})
        const n = this.state.metas.length
        if ( n < this.state.results.length ) {
            this.props.api.post({
                action:'gets',
                project:this.state.project,
                lang:this.state.lang,
                ids:this.state.results.slice(n,n+c)
            }, this.receive.bind(this,n))
        }
    }

    receive(n,res) {
        this.setState( ( prevState, props )=> {
            let metas = prevState.metas
            let domains = prevState.domains
            res.forEach( (meta,i) => {
                const index = n+i
                if ( meta ) {
                    const url = meta.url
                    const mdomain = url.match(/^https?:\/\/([^\/]+)/)
                    const domain = mdomain ? mdomain[1] : false
            
                    if ( domain ) {
                        if ( !( domain in domains )) {
                            domains[domain] = []
                        }
                        if ( ! domains[domain].includes(index) ) {
                            domains[domain].push( index )
                            domains[domain].sort()
                        }
                    }

                    metas[index] = {url:url,meta:meta.meta,domain:domain}
                } else metas[index] = false
            })
            setTimeout( () => { this.loading = false }, 1000 )
            return {metas:metas,domains:domains,loading:false}
        })
    }

    updateResults(res) {
        console.log('updateResults',res.results)
        this.setState({
            metas:[],
            results:res.results,
            project:res.project,
            lang:res.lang,
            domains:{}
        }, this.loadNextMeta.bind(this))
    }

    getDomainGroups() {
        let domainGroups = []
        for ( const [domain,group] of Object.entries( this.state.domains ) ) {
            if ( false && this.state.unfoldedDomains.includes(domain) ) {
                // Split group into singletons, because domain is unfolded
                for ( const index of group ) {
                    domainGroups.push([index])
                }
            } else {
                domainGroups.push(group)
            }
        }
        return domainGroups.sort( (a,b)=>a[0]-b[0] )
    }

    collapse( domain ) {
        this.setState( prevState => { 
            let {unfoldedDomains} = prevState
            if ( unfoldedDomains.includes(domain) ) {
                unfoldedDomains = unfoldedDomains.filter( d => d!=domain )
            } else {
                unfoldedDomains.push(domain)
            }
            return { unfoldedDomains:unfoldedDomains }
        } )
    }

    renderGroups() {
        const { domains, project, lang, results, metas } = this.state
        const api = this.props.api
        const domainGroups = this.getDomainGroups()// Object.values( domains ).sort( (a,b)=> a[0]-b[0] )
        console.log(domainGroups)

        let rt = []
        for ( const ids of domainGroups ) {
            const result = results[ids[0]]
            if ( metas[ids[0]] ) {
                const domain = metas[ids[0]].domain
                const unfolded = this.state.unfoldedDomains.includes(domain)
                rt.push( <TrinetResult 
                    key={project+'/'+lang+'/'+result} 
                    index={ids[0]}
                    url={metas[ids[0]].url}
                    meta={metas[ids[0]].meta}
                    domain={domain}
                    api={api} 
                    lang={lang}
                    project={project}
                    result={result}
                    hasSubresults={ids.length-1}
                    collapse={this.collapse.bind(this)}
                    unfolded={unfolded}
                />  )
                let subrt = []
                if ( unfolded ) {
                    for ( let i = 1 ; i < ids.length ; i++ ) {
                        const result = results[ids[i]]
                        subrt.push(
                            <TrinetResult 
                                key={project+'/'+lang+'/'+result} 
                                index={ids[i]}
                                url={metas[ids[i]].url}
                                meta={metas[ids[i]].meta}
                                domain={metas[ids[i]].domain}
                                api={api} 
                                lang={lang}
                                project={project}
                                result={result}
                                subResult={true}
                                collapse={this.collapse.bind(this)}
                            />
                        )
                    }
                }
                rt.push(
                    <div className={'sub-results'+(subrt.length?' expanded':'')} key={'s/'+project+'/'+lang+'/'+result}>{subrt}</div>
                )
            }
        }
        return rt
    }

    renderFlat() {
        const { project, lang, results, metas } = this.state
        const api = this.props.api

        let rt = []
        for ( let index = 0 ; index < metas.length() ; index ++ ) {
            const result = results[index]
            rt.push( <TrinetResult 
                key={project+'/'+lang+'/'+result} 
                index={index}
                url={metas[index].url}
                meta={metas[index].meta}
                domain={metas[index].domain}
                group={[index]}
                api={api} 
                lang={lang}
                project={project}
                result={result} 
            /> )
        }
        return rt
    }

    render() {

        return this.state.results.length ? <div className="results-list" ref={this.resultsRef}>

            { this.state.groupDomains ? this.renderGroups() : this.renderFlat() }

            <div ref={this.bottomRef} className={this.state.loading ? 'next-button-loading' : 'next-button'} onClick={this.onIntersect.bind(this)}>
                ▾
            </div>
        </div> : ''
    }
} 

export default TrinetResults;

