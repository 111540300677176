import $ from 'jquery';
import React from 'react';
import LinkBox from './LinkBox';

function stripTags(textToEscape) {
    return textToEscape.split(/<\s*br\s*(?:\/\s*)?>|<\s*\/\s*p\s*>\s*<\s*p\s*>/).map(
        row => {
            let acc = []
            const patt = $('<p></p>').html(row).text().split(/(https?:\/\/)([^\/]+\/)(\S+)/g)
            for ( let i = 0 ; i < patt.length ; ) {
                acc.push(<span key={i}>{patt[i]}</span>)
                if ( ++i < patt.length ) {
                    acc.push(<a key={i} href={patt[i]+patt[i+1]+patt[i+2]}
                        target="_blank"
                        onClick={e=>{e.stopPropagation()}}>{patt[i]}{patt[i+1]}...</a>)
                }
                i += 3
            }
            return acc
        }
    )
}

export default function renderMastodon( api, id, lang, url, meta ) {

    if ( !meta.body ) return ''
    let cleanInstance = meta.instance ? meta.instance.replace(/https?:\/\//,'') : ''
    let datec = ( meta.created_at && typeof meta.created_at == 'number' )? new Date(meta.created_at*1000) : 0
    let created_at = datec
    ? <a className="badge">{datec.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}</a>
    : ''
    let imageBox = meta.image
    ? <p><a href={meta.image} target="_blank" onClick={e=>{e.stopPropagation()}}><img className="preview" src={meta.preview_image}/></a></p>
    : ''
    let i = 0

    const linkTagMatch = meta.body.match(/<[^>]+target="_blank"[^>]*>/)
    const linkTag = linkTagMatch ? linkTagMatch[0] : false
    const linkMatch = linkTag ? linkTag.match(/\bhttps?:\/\/[-a-zA-Z0-9()@:%_\+.~?&//=]+/) : false
    const link = linkMatch ? linkMatch[0] : false

    return <React.Fragment>
        <div className="header">
            {meta.aauthor ? <img className="avatar" src={meta.aauthor}/> : <></>}
            <div className={meta.aauthor ? 'description' : 'description inline'}>
                <div className="author" onClick={api.addMeta.bind(api,{'author':meta.author})} title="Search on this author">
                    {meta.dauthor || meta.author}
                </div>
                <div className="instance" onClick={api.addMeta.bind(api,{'instance':meta.instance})} title="Search in this instance">@{cleanInstance}</div>
            </div>
        </div>
        <div className="date">{created_at}</div>
        <div className="content">
{/*         <div className="leftbox">
                {imageBox}
             </div> */}
            <div className="rightbox">
                {stripTags(meta.body).map(
                    row => <p className="resume" key={i++} onClick={e=>{window.open(url)}}>{row}</p>
                )}
            </div>
        </div>
        {link ? <LinkBox link={link} api={api}/> : <></> }
    </React.Fragment>
}