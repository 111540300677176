import $ from 'jquery';
import renderDefault from './renderers/renderDefault'
import CommonApi from './commonApi';

class TrinetApi {

    constructor(url,entry,options={}) {

        this.common = new CommonApi(url)

        this.price = 0
        this.firstSubscribe = false
        this.firstLogin = false
        this.useHash = !( options['nohash'] || false )
        this.useScroll = !( options['noscroll'] || false )
        this.url = url+'service/search.api.php' 
        this.backend = url
        const acceptedLanguages = /^(?:en|fr|ar|da|nl|fi|de|hu|it|no|pt|ro|ru|es|sv)/
        const langMatch = ( navigator && navigator.language && navigator.language.match(acceptedLanguages) )
        this.userLang = langMatch ? langMatch[0] : 'en'
        this.getProjectList()

        this.views = {
            'projects':[this],
            'meta':[],
            'results':[],
            'projectName':[this],
            'metawordCounts':[],
            'resultCount':[],
            'query':[]
        }

        this.projectName = false
        this.projects = []
        this.lang = false

        this.query = ''
        this.queryString = ''
        this.metawords = []

        this.renderers = {}
        this.resultBox = false

        this.defaultProjectName = false
        this.defaultLang = this.userLang
        this.defaultQueryString = ''

        if ( window.location.hostname == 'wikiwix.com' ) {
            this.defaultProjectName = 'archives'
        }

        let regEntry = /\?([^\/]*)(?:\/([-a-z]*)(?:\/(.*))?)?$/
        let match = window.location.search.match( regEntry )
        if ( !match ) match = entry.match( regEntry )

        if ( match ) this.defaultProjectName = decodeURIComponent(match[1])
        if ( match && match[2] ) this.defaultLang = decodeURIComponent(match[2])
        if ( match &&( match[3] !== undefined )) this.defaultQueryString = decodeURIComponent(match[3])

        if ( this.useHash ) {
            window.addEventListener("hashchange", this.onHashChange.bind(this), false);
            this.onHashChange()
        } else {
            this.projectName = this.defaultProjectName
            this.lang = this.defaultLang
            this.queryString = this.defaultQueryString
            const { query, metawords } = this.decodeQueryString( this.queryString )
            this.query = query
            this.metawords = metawords
        }
    }

    cacheUrl( url ) {
        return this.backend+'cache/index2.php?url='+encodeURIComponent(url)
    }

    cacheDisplayUrl( url ) {
        return 'https://archive.wikiwix.com/cache/display2.php?url='+encodeURIComponent(url)
    }

    hasProjectControl() {
        return ! this.defaultProjectName
    }

    onHashChange() {

        let match = window.location.hash.match(/#([^\/]*)(?:\/([-a-z]*)(?:\/(.*))?)?$/)
        let queryString = ( match &&( match[3] !== undefined ))? decodeURIComponent(match[3]) : ''
        if ( queryString.startsWith( '!subscribe!' ) ) {
            this.firstSubscribe = true
            this.common.returnAfter = queryString.substring(11)
            queryString = ''
        }
        if ( queryString.startsWith( '!login!' ) ) {
            this.firstLogin = true
            this.common.returnAfter = queryString.substring(7)
            queryString = ''
        }
        const { query, metawords } = this.decodeQueryString( queryString )

        this.notifyChanges({
            projectName: match ? decodeURIComponent(match[1]) : this.defaultProjectName,
            lang: ( match && match[2] )? decodeURIComponent(match[2]) : this.defaultLang,
            query: query,
            metawords: metawords
        })
    }

    makeQueryString( query, metawords ) {
        if ( query.match(/^\s*like\s*:/) ) return query
        let queryString = query.trim()
        metawords.forEach( metaword => {
            queryString +=' '+metaword[0]+':'+metaword[1]
        })
        return queryString
    }

    /** Splits on the first occurence of ':' (string.split don't do that, even with a limit=2) */
    splitMetaword(word) {
        const i = word.indexOf(':')
        return ( i<0 ) ? ['',word] : [word.substring(0,i),word.substring(i+1)]
    }
    
    decodeQueryString( queryString ) {
        if ( queryString.match(/^\s*like\s*:/) ) return { query:queryString, metawords:[] }
        let query = ''
        let metawords = []
        let currentMetaword = false
        queryString.split(' ').forEach( token => {
            let m = this.splitMetaword(token)
            if ( m[0] ) {
                if ( currentMetaword ) metawords.push( currentMetaword )
                currentMetaword = m
            } else {
                if ( currentMetaword ) {
                    currentMetaword[1] += ' '+m[1]
                } else {
                    if ( query.length ) query += ' '
                    query += m[1]
                }
            }
        })
        if ( currentMetaword ) metawords.push( currentMetaword )

        return { query:query, metawords:metawords }
    }

    /**
     * @param {*} options { query:string query, metawords:array of tuples (type of metaword, content of metaword), like ('instance','https://coucou.com/') }
     */
    notifyChanges(options) {
        const projectName = options.projectName || this.projectName
        const lang = options.lang || this.lang
        
        const query = ( options.query !== undefined )? options.query : this.query
        const metawords = ( options.metawords !== undefined )? options.metawords : this.metawords
        const queryString = this.makeQueryString( query, metawords )

        if ( this.useHash ) window.location.href='#'+(projectName || '')+'/'+(lang || '')+'/'+queryString

        if (( this.projectName != projectName )||( this.lang != lang )) {
            this.projectName = projectName
            this.lang = lang
            this.views.projectName.forEach(view => {
                view.updateProjectName(projectName,lang)
            });
        }

        if ( this.queryString != queryString ) {
            this.query = query
            this.metawords = metawords
            this.queryString = queryString
            this.views.query.forEach(view=>{
                view.updateQuery(query,metawords)
            })
        }
    }


    setProjectName(projectName) {
        this.notifyChanges({projectName:projectName})
    }
    
    setLang(lang) {
        this.notifyChanges({lang:lang})
    }

    setQuery(query) {
        this.notifyChanges({query:query})
    }

    updateProjectName() {
        if ( this.projectName && this.lang ) {
            for ( const metaword of this.getMetaWordsListed() ) {
                this.post({
                    action:'countDocumentsByMetawords',
                    lang:this.lang,
                    project:this.projectName,
                    prefix:metaword+':'
                })
            }
        }
    }

    updateProjects() {
        this.updateProjectName()
    }

    getMetaWordsListed() {
        let projectName = this.projectName
        let project = this.projects.find( p => p.name == projectName )
        return project ? project.metaWordListed : []
    }

    /**
     * @returns The list of metaword types that exist in current project e.g. ['instance','author']
     */
    getMetaWords() {
        let projectName = this.projectName
        let project = this.projects.find( p => p.name == projectName )
        let listed = ( project && project.metaWordListed )|| []
        let unlisted = ( project && project.metaWordUnlisted )|| []
        return project ? listed.concat( unlisted ) : []
    }

    registerRenderer( name, renderer ) {
        this.renderers[name] = renderer
    }

    getRenderer( name ) {
        return this.renderers.hasOwnProperty(name) ? this.renderers[name] : renderDefault
    }

    registerAsView( type, view ) {
        this.views[type].push(view)
    }

    unregisterAsView( type, view ) {
        this.views[type] = this.views[type].filter( v => view!=v )
    }

    getProjectList() {
        this.post({
            action:'list'
        })
    }

    registerAsResultBox( ref ) {
        this.resultBox = ref
    }

    unregisterAsResultBox( ref ) {
        if ( this.resultBox == ref ) this.resultBox = false
    }
    
    scrollToResults() {
        if ( this.useScroll && this.resultBox && this.resultBox.current ) {
            this.resultBox.current.scrollIntoView()
        }
    }

    receive(res) {
        const me = this
        if ( res.price ) {
            this.price = res.price
        }
        if ( res.projects ) {
            this.projects = res.projects.filter( project => project.name != 'classifier' )
            this.views.projects.forEach(view => {
                view.updateProjects(me.projects)
            });
        }
        if ( res.results ) {
            this.views.results.forEach(view => {
                view.updateResults(res)
            });
            this.views.resultCount.forEach(view => {
                view.updateResultCount( res.results.length, res.lang )
            })
        }
        if ( res.all_count ) {
            this.views.resultCount.forEach(view => {
                view.updateAllCount( res.all_count )
            })
        }
        if ( res.url ) {
            let m = res.url.match(/https?:\/\/([^\/]+)(\/|$)/)
            if ( m ) {
                this.views.meta.forEach(view => {
                    view.knownMeta({domain:m[1]})
                })
            }
        }
        if ( res.url && res.meta ) {
            this.views.meta.forEach(view => {
                view.knownMeta(res.meta)
            })
        }
        if ( res.metawordCounts ) {
            this.views.metawordCounts.forEach(view => {
                view.updateMetawordCounts(res.metawordCounts)
            })
        }
    }

    searchConnex( url ) {
        this.setQuery('like:'+url)
    }

    addMeta( meta ) {
        this.views.meta.forEach(view => {
            view.addMeta(meta)
        })
        
    }

    getToken() {
        var match = document.cookie.match(/(?:^|;)\s*token\s*=\s*([0-9a-f]+)/)
        var ret = match ? match[1] : false
        return ret
      }

    post( what, onSuccess, onError ) {
        var me = this
        what.token = this.getToken()
        $.ajax({
            url: this.url,
            type:'POST',
            data:what,
            dataType: 'json',
            success: function(res){
                if ( res.error ) {
                    if ( onError ) onError(res.error)
                    else me.showError(res.error)
                } else {
                    if ( onSuccess ) onSuccess(res)
                    me.receive(res)
                    me.common.receive(res)
                }
            },
            error: function(e) {
                me.showError('Internal error 1')
            }
        });
    }

    showError(error) {
        console.log(error)
    }


}

export default TrinetApi;
