import React from 'react';
import './LoginForm.css';
import ControlledInput from './controlledInput';
import logout_image from './img/logout-box-line.png';
import user_image from './img/24user-3-line.png'
import PaypalForm from './PaypalForm';
import wikipedia_logo from './img/Wikipedia_Logo_128.png'

export default class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            login:false,
            restricted:false,
            subscription:false,
            collapsed:true,
            recoverMessage:false,
            left:1,
            error:false,
            log_login:'',
            log_password:'',
            reg_login:'',
            reg_password:'',
            reg_password2:'',
            reg_email:''
        }
        this.t = this.props.api.common.t
    }

    componentDidMount() { 
        this.props.api.common.registerAsView(this)
        if ( this.props.api.firstSubscribe ) {
            this.setState({collapsed:false,left:2})
        }
        if ( this.props.api.firstLogin ) {
            this.setState({collapsed:false,left:1})
        }
    }

    componentWillUnmount() {
        this.props.api.common.unregisterAsView(this)
    }

    notifyLogin(login) {
        this.setState({login:login,error:false})
    }

    notifyRestricted(restricted) {
        this.setState({restricted:restricted})
    }

    notifySubscription(subscription) {
        this.setState({subscription:subscription})
    }

    doLogin(e) {
        this.setState({error:false})
        this.props.api.common.login( 
            this.state.log_login, 
            this.state.log_password,
            (error)=>{
                this.setState({error:error})
            }
        )
    }

    doLogout(e) {
        this.setState({error:false})
        this.props.api.common.logout()
    }

    doRegister(e) {
        if ( ! this.state.reg_login ) {
            this.setState({error:'Please choose a login'})
            return
        }
        if ( ! this.state.reg_password ) {
            this.setState({error:'Please enter a password'})
            return
        }
        if ( this.state.reg_password != this.state.reg_password_2 ) {
            this.setState({error:'Please enter twice the same password'})
            return
        }
        this.setState({error:false})
        this.props.api.common.register( 
            this.state.reg_login, 
            this.state.reg_password, 
            this.state.reg_email,
            (error)=>{
                this.setState({error:error})
            }
        )
    }

    doRecover(e) {
        this.props.api.common.recover(
            this.state.rec_login,
            this.state.rec_email,
            (res)=>{
                this.setState({'recoverMessage':true})
            },
            (res)=>{
                this.setState({'recoverMessage':true})
            }
        )
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    

    displayLogin() {
        const login = this.state.login
        const pmWikimedia = login.match(/^wp:(.*)$/)
        if ( pmWikimedia ) {
            
            return 'Wikimedia : '+this.capitalizeFirstLetter( pmWikimedia[1].replace('_',' ') )
        }
        return login
    }

    onConnectWikipedia() {
        window.location.href = 'https://meta.wikimedia.org/w/rest.php/oauth2/authorize?client_id=d5b90e1c2206b4c83eb07efe4f3d6a78&response_type=code&state='
            + this.props.api.getToken()+';'+encodeURIComponent(window.location.href)
    }

    renderCollapsed() {
        const me = this
        return <div className="login-form login-form-bandeau login-form-bandeau-clickable" onClick={()=>me.setState({collapsed:false})}>
            <div className="login-bandeau">
                <img src={user_image} />
            </div>
            <div className="login-bandeau">
                {this.t('login_or_create')}
            </div>
            <div className="login-bandeau-expand"></div>
        </div>
    }

    renderLoggedOut() {
        const boxClassName = this.state.left ? 'login-form-box left'+this.state.left :'login-form-box'
        return this.state.collapsed ? this.renderCollapsed() : <div className="login-form">

            <div className={boxClassName}>
                <h2>{this.t('forgot_password')}</h2>
                <p>{this.t('forgot_password_explain')}</p>
                <div className="inputs">
                    <label>{this.t('Enter your login')}<br/>
                    <ControlledInput type="text" container={this} property="rec_login" onSubmit={this.doRecover.bind(this)}/>
                    </label>
                    <label>{this.t('or your email')}<br/>
                    <ControlledInput type="text" container={this} property="rec_email" onSubmit={this.doRecover.bind(this)}/>
                    </label>
                </div>
                {this.state.recoverMessage ? <div className="message">If the account exists, a recovery email has been sent.</div>:<></>}
                <button onClick={this.doRecover.bind(this)}>{this.t('Recover')}</button>
                <div className="fork" onClick={()=>this.setState({left:1})}>{this.t('Back to login')}&nbsp;▹</div>
            </div>

            <div className={boxClassName}>
                <h2>{this.t('Login_connect')}</h2>
                <div className="inputs">
                    <label>{this.t('Login')}<br/><ControlledInput type="text" container={this} property="log_login" onSubmit={this.doLogin.bind(this)}/></label>
                    <label>{this.t('Password')}<br/><ControlledInput type="password" container={this} property="log_password" onSubmit={this.doLogin.bind(this)}/></label>
                </div>
                {this.state.error ? <div className="error">{this.state.error}</div>:<></>}
                <button onClick={this.doLogin.bind(this)}>{this.t('Login_connect')}</button>
                <div className="fork" onClick={()=>this.setState({left:2})}>{this.t('Create an account')}&nbsp;▹</div>
                <div className="fork" onClick={()=>this.setState({left:0})}>◃&nbsp;{this.t('forgot_password')}</div>


                <div className="wikimedia_box">
                <p className="wikimedia_title">{this.t('Login with Wikimedia')}</p>
                <p><img src={wikipedia_logo} className="wikimedia_button" onClick={this.onConnectWikipedia.bind(this)}/></p>
                <p>{this.t('wikimedia_explain')}</p>
                </div>
            </div>

            <div className={boxClassName}>
                <h2>{this.t('Create an account')}</h2>
                <div className="fork" onClick={()=>this.setState({left:1})}>◃&nbsp;{this.t('Already have an account ?')}</div>
                <div className="inputs">
                    <label>{this.t('pick_login')}<br/>
                    <ControlledInput type="text" container={this} property="reg_login" onSubmit={this.doRegister.bind(this)}/>
                    </label>
                    <label>{this.t('pick_password')}<br/>
                    <ControlledInput type="password" container={this} property="reg_password" onSubmit={this.doRegister.bind(this)}/>
                    </label>
                    <label>{this.t('pick_password_again')}<br/>
                    <ControlledInput type="password" container={this} property="reg_password_2" onSubmit={this.doRegister.bind(this)}/>
                    </label>
                    <label>{this.t('your_email_optional')}<br/>
                    <ControlledInput type="text" container={this} property="reg_email" onSubmit={this.doRegister.bind(this)}/>
                    </label>
                </div>
                {this.state.error ? <div className="error">{this.state.error}</div>:<></>}
                <button onClick={this.doRegister.bind(this)}>{this.t('Register')}</button>
            </div>


        </div>
    }

    renderLoggedIn() {

        const subscription = this.state.subscription
        const ds = ( subscription && subscription.valid_until )? 1000*subscription.valid_until : false;
        let subscriptionBox = <></>
        let subscriptionLine = <></>
        if ( !( this.state.restricted || subscription )) {
            subscriptionBox = <>
                <p>{this.t('have_unlimited')}</p>
            </>
            subscriptionLine = <><span className="sep">{this.state.collapsed ? '▸' : '▾'}</span>Unlimited access</>
        } else {
            if ( subscription ) {
                if ( subscription.status == 'ACTIVE' ) {
                    subscriptionBox = <>
                    <p>{this.t('have_subscription')}</p>
                    <button className="btn-search" onClick={(e)=>{e.stopPropagation() ; this.props.api.common.cancelSubscription( subscription.id )}}>Cancel my subscription</button></>
                } else {
                    if ( subscription.valid && ds ) {
                        subscriptionBox = <>{this.t('still_have_unlimited',this.t(ds))}</>
                    }
                    subscriptionBox = <>{subscriptionBox}<br/><PaypalForm api={this.props.api}/></>
                }
                subscriptionBox =  <><h2>{this.t('Your unlimited plan is')} {this.t(subscription.status)}</h2> {subscriptionBox}</>
                subscriptionLine = <><span className="sep">{this.state.collapsed ? '▸' : '▾'}</span>{this.t(subscription.status).toLowerCase()}</>
            } else {
                subscriptionBox = <PaypalForm api={this.props.api}/>
                subscriptionLine = <><span className="sep">{this.state.collapsed ? '▸' : '▾'}</span>{this.t('No subscription')}</>
            }
        }


        return <><div className="login-form login-form-bandeau" onClick={e=>this.setState((prev)=>({collapsed:!prev.collapsed}))}>

            <div className="login-bandeau">
                <img src={user_image} />
            </div>
            <div className="login-bandeau">
            {this.displayLogin()}
            </div>
            <div className="login-bandeau">
            {subscriptionLine}
            </div>
            <div className="login-bandeau-expand"></div>
            <div className="login-bandeau login-bandeau-rightmost">
                <img src={logout_image} title="logout" onClick={this.doLogout.bind(this)}/>
            </div>

        </div> { this.state.collapsed ? '' : this.state.restricted ? <div className="login-form">
            <div className="login-form-box">
                <h2>{this.t('Subscribe to Wikiwix Archive')}</h2>
                <p>{this.t('for_x_get_access',this.props.api.price)}</p>
                <PaypalForm api={this.props.api}/>
            </div>
        </div> : <div className="login-form">

            <div className="login-form-box">
                {subscriptionBox}
            </div>

        </div> }</>

    }

    render() {
        return this.state.login ? this.renderLoggedIn() : this.renderLoggedOut()
        
    }
}
