
import './renderDefault.css'
import imgConnex from './file-search-line.png'
import LinkBox from './LinkBox';

function stripTags(textToEscape) {
    return textToEscape.replace(/<[^>]+>/g, '');
}

const projectsInArchive = ['orange','skyblogs']

export default function renderDefault(api,id,lang,url,meta,projectName,hasSubresults) {

    const originalUrl = url
    url = url.replace(/^https?:\/\/web\.archive\.org\/web\/[0-9]+\//,'')
    const pregs = url.match( /^https?:\/\/([^\/]+)/ )
    const hostname = pregs ? pregs[1] : ''
    if ( projectName == 'archives' ) url = 'https://archive.wikiwix.com/cache/index2.php?url='+encodeURI(url)

    const targetUrl = ( projectsInArchive.includes(api.projectName) )? api.cacheUrl( url ) : url

    const title = meta.title ? <a href={targetUrl} className="title" target="_blank">{stripTags(meta.title)}</a> : <></>
    const datec = ( meta.created_at && typeof meta.created_at == 'number' )? new Date(meta.created_at*1000) : 0
    const author = meta.author 
    ? <a className="badge" onClick={api.addMeta.bind(api,{'author':meta.author})}>{meta.author}</a>
    : ''
    const discipline = meta.discipline
    ? <a className="badge">{meta.discipline}</a>
    : ''
    const created_at = datec
    ? <a className="badge">{datec.toDateString()}</a>
    : ''
    
    const type = meta.type ? <a className="badge"  onClick={api.addMeta.bind(api,{'type':meta.type})}>{meta.type}</a>
    : ''

    let body = meta.body ? <div className="body">{stripTags(meta.body)}</div> : ''
    if ( meta.abstract && ! meta.abstract.match(/^\s*Lien externe\s*$/) ) body = <>{meta.abstract}</>

    const wikis = meta.wikis ? <p className="resume wikis">
        {meta.wikis.map(wiki=>{
            const pm = wiki.match(/^([-a-z]+\.[a-z]+\.org)\/(.+)$/)
            return <span key={wiki}>▸ <a target="_blank" href={'https://'+pm[1]+'/wiki/'+pm[2]}>{pm[2]}</a></span>
        })}
    </p>:''

    return <>
        <div className="header">
            <div className="author" onClick={ /*api.addMeta.bind(api,{'domain':hostname}) */ ()=>{} }>{hostname}</div>
            <div className="tools"> {type} <img src={imgConnex} title="Search similar documents" onClick={api.searchConnex.bind(api,originalUrl)}/></div>
        </div>
        <div className="content">
            <div className="rightbox">
               <p className="footer">{author} {discipline} {created_at}</p>
               <p className="resume">{title}</p>
               <p className="resume">{body}</p>
               {wikis}
            </div>
        </div>
        {originalUrl ? <LinkBox link={originalUrl} api={api} showDomain={false}/> : <></> }
    </>
}