const l10n_default = 'en'
const l10n_map = {
    'en':{
        'without_unlimited':'Without unlimited access, you are limited you to 3 results per queries, and Wikiwix Archive is showing a popup notice each time you visit.',
        'subscribe_to':'Subscribe to an unlimited access to Wikiwix search engine, and popup free Wikiwix Archive, for {1}€ per month',
        'login_or_create':'Login or create an account',
        'forgot_password':'Forgot your password ?',
        'forgot_password_explain':'If you provided a valid email during registration, we will send you a recovery email.',
        'Login_connect':'Login',
        'wikimedia_explain':'and get free unlimited access to Archive and Wikiwix',
        'pick_login':'Pick a login',
        'pick_password':'Pick a password',
        'pick_password_again':'Type your password again',
        'your_email_optional':'Your email (optional)',
        'have_subscription':'You have subscribed to unlimited access to Wikiwix search engine and popup free Wikiwix Archive.',
        'have_unlimited':'You have unlimited access to Wikiwix search engine and popup free Wikiwix Archive.',
        'still_have_unlimited':'You still have unlimited access to Wikiwix Archive until {1}',
        'for_x_get_access':'For {1} € per month, get full access to Wikiwix Archive',


    },
    'fr':{
        'without_unlimited':'Sans accès illimité, vous êtes limité à 3 résultats par requète, et Wikiwix Archive affiche une popup à chaque visite.',
        'subscribe_to':'Abonnez-vous à Wikiwix illimité, et Wikiwix Archive sans popup, pour {1}€ par mois',
        'Pick a plan':'Choisissez un abonnement',
        'login_or_create':'Se connecter ou créer un compte',
        'forgot_password':'Mot de passe oublié ?',
        'forgot_password_explain':'Si vous avez fourni un email valide lors de l\'inscription, vous allez recevoir un email de récupération.',
        'Enter your login':'Entrez votre pseudo',
        'or your email':'ou votre email',
        'Recover':'Récupérer',
        'Back to login':'Retour à la connexion',
        'Login':'Pseudo',
        'Login_connect':'Connexion',
        'Password':'Mot de passe',
        'Create an account':'Créer un compte',
        'Login with Wikimedia':'Connectez-vous avec votre compte Wikimedia',
        'wikimedia_explain':'et accédez gratuitement à Archive et Wikiwix illimités',
        'Already have an account ?':'Vous avez déjà un compte ?',
        'pick_login':'Choisissez un pseudo',
        'pick_password':'Choisissez un mot de passe',
        'pick_password_again':'Tapez votre mot de passe une seconde fois',
        'your_email_optional':'Votre email (facultatif)',
        'Register':'Inscrivez-vous',
        'have_subscription':'Vous êtes abonné à Wikiwix illimité et Wikiwix Archive sans popups.',
        'have_unlimited':'Vous bénéficiez d\'un accès illimité à Wikiwix et Wikwix Archive sans popups.',
        'still_have_unlimited':'Vous conservez un accès illimité à Wikiwix et Wikiwix Archive jusqu\'au {1}',
        'Your unlimited plan is':'Votre abonnement est',
        'ACTIVE':'ACTIF',
        'CANCELLED':'ANNULÉ',
        'No subscription':'Pas d\'abonnement en cours',
        'Subscribe to Wikiwix Archive':'Abonnez-vous aux Archives Wikiwix',
        'for_x_get_access':'Pour {1} € par mois, obtenez un accès illimité aux archives Wikiwix',
        '1€ per month':'1€ par mois',
        '10€ per year':'10€ par ans',
        '5€ per year (student offer)':'5€ par ans (offre étudiants)',
        'results in':'résultats parmis',
        'Filter':'Filtrer',
    }
}

export default class l18n {

    constructor() {
        this.locale = navigator.language
        this.lang = this.locale.split('-')[0]
        this.dateFormatter = new Intl.DateTimeFormat(this.locale)
        console.log('Got lang '+this.lang)
        if ( !l10n_map.hasOwnProperty(this.lang) ) this.lang = l10n_default
        this.map = l10n_map[this.lang]
    }

    get(key,...args) {
        if (typeof key === 'number') {
            return this.dateFormatter.format(key)
        }

        if ( this.map.hasOwnProperty(key) ) return this.map[key].replace(/{(\d+)}/g, (match, index) => {
            return typeof args[index-1] !== 'undefined' ? args[index-1] : match;
        });
        return key
    }

    getter() { return this.get.bind(this) }

    formatDate( time ) {
        return this.dateFormatter.format(time)
    }

}